import React from 'react';
import '../../style/animation.css';

const Animation = () => {
  return (
    <div className="animation-container">
      <div className="animated-element"></div>
    </div>
  );
};

export default Animation;
